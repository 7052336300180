<template>
  <div invitation-event-bottom-banner-alt>
    <div class="bottom-banner">
      <img class="right-top" src="/img/coaching/coach-event-circle-right-top-blue.svg">
      <img class="right-bottom" src="/img/coaching/coach-event-circle-right-bottom-blue.svg">
      <img class="left-top" src="/img/coaching/coach-event-circle-left-top-blue.svg">
      <img class="left-bottom" src="/img/coaching/coach-event-circle-left-bottom-blue.svg">
      <div class="bottom-banner--content">
        <h1 class="bottom-banner--desc">
          <span>이미 <em>수강중이신가요?</em></span>
        </h1>
        <button class="bottom-banner--button" @click="onClick">{{ $t('coaching.BT_LABEL_GO_SEND_REFERRAL') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvitationEventBottomBannerAlt',
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[invitation-event-bottom-banner-alt] { .w(100%); .bgc(#2f50f8);
  @media all and (min-width: 800px) {
    .left-top {.t(0); .l(242);}
    .left-bottom {.l(24);.b (0);}
    .right-top {.r(1); .t(0);}
    .right-bottom { .r(269); .b(0);}
    .bottom-banner--button { .fs(24); .mt(32); .br(20); .w(360); .h(74);}
    .bottom-banner--desc { .fs(36);}
    .bottom-banner { .h(280);}
  }

  @media all and (max-width: 799px) {
    .bottom-banner { overflow-x: hidden;}
    .left-top { .t(0); .l(calc(-0.5 * 77px));}
    .left-bottom { .b(0); .l(calc(-0.5 * 390px));}
    .right-top { .t(0); .r(calc(-0.5 * 390px));}
    .right-bottom { .b(0); .r(calc(-0.5 * 77px));}
    .bottom-banner--button { .fs(18); .mt(12); .br(12); .w(310); .h(58);}
    .bottom-banner--desc { .fs(24);}
    .bottom-banner { .h(184);}
  }

  .bottom-banner--wrapper { .bgc(#8d1be7);
    .left-top,
    .right-bottom { .w(77);}
    .left-bottom,
    .right-top { .w(390);}
  }

  .bottom-banner { .m(auto); .max-w(1280); .rel;
    > img { .abs;}
  }
  .bottom-banner--content { .abs; transform: translate(-50%, -50%); .t(50%); .l(50%); text-align: center;}

  .bottom-banner--button { .bgc(#191919); .c(#fff); border: none; cursor: pointer;}

  .bottom-banner--desc { .block; font-family: KotraBold; .c(#fff); .bold;
    > span > em { font-style: normal; .c(#fed1ff);}
  }
}
</style>
