<template>
  <tr table-benefits-row>
    <td class="benefit-title">
      <img :src="!alt ? '/img/icon/table-check.svg' : '/img/icon/table-check-purple.svg'">
      <slot name="title"></slot>
    </td>
    <td class="benefit-divider">
      <div class="vertical-divider"></div>
    </td>
    <td class="benefit-desc">
      <slot name="desc"></slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'TableBenefitsRow',
  props: { alt: { type: Boolean, default: false } },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[table-benefits-row] {
  .benefit-title { .w(82); border-bottom: 16px solid transparent; .fs(14); .c(#fff); .flex; .items-center; .bold;
    > img {.w(14); .h(12); .mr(8);}
  }
  .benefit-divider {
    .vertical-divider { content: ''; .w(0); border-right: solid 0.5px #fff; .o(0.2); .h(20); .mr(30); }
  }
  .benefit-desc { border-bottom: 16px solid transparent;
    > p {.fs(14); .c(#fff);
      > span {.fs(14); .o(0.35); }
    }
    > em {.fs(14); .c(#fff); text-decoration-line: underline; text-decoration-style: solid; text-decoration-color: rgba(255, 255, 255, 0.1); .bold; text-underline-offset: -16px; text-decoration-thickness: 14px; }
    > ul {
      > li {.c(rgba(255, 255, 255, 0.6)); .ml(16); .fs(14); .rel;
        &:before { .abs; .bgc(#fff); .o(0.6); content: ''; .w(5); .h(5); .br(100%); .l(-15); .t(12); }
      }
    }
  }

  @media (@tp-up) {
    .benefit-title {.fs(24); .w(150);
      > img {.w(22); .h(20); .mr(12); }
    }
    .benefit-desc {
      > p {.fs(24);
        > span {.fs(18); }
      }
      > em {.fs(24);}
      > ul {
        > li {.c(rgba(255, 255, 255, 0.6)); .ml(16); .fs(18); .rel;
          &:before { .abs; .bgc(#fff); .o(0.6); content: ''; .w(5); .h(5); .br(100%); .l(-15); .t(12); }
        }
      }
    }

  }
}

</style>
