<template>
  <div coach-event-invitation-receive>
    <button class="btn-prev" @click="prevBtnHandler">
      <img src="/img/icon/icon-arrow-down-white.svg">
    </button>
    <section class="banner">
      <InvitationEventBanner bannerType="receive" />
    </section>
    <section class="controller-wrap">
      <div class="controller-top"><p>{{ $t('coaching.PC_LABEL_INPUT_INVITATION') }}</p></div>
      <div class="controller-bottom">
        <input v-model="invitationCode" class="invitation-code-input" />
        <ColorButton class="receive-btn" color="black" @click="receive">{{ $t('coaching.BT_LABEL_RECEIVE_REFERRAL') }}</ColorButton>
      </div>
    </section>
    <section class="benefit">
      <InvitationEventMiddleAlt />
    </section>
    <section class="guide">
      <InvitationEventGuideAlt />
    </section>
    <section class="bottom-banner">
      <InvitationEventBottomBannerAlt @click="goSend" />
    </section>
    <section class="bottom-detail">
      <InvitationEventBottomDetailAlt />
    </section>
  </div>
</template>

<script>
import ColorButton from '@shared/components/common/ColorButton.vue';
import InvitationEventBanner from '@/views/components/coaching/event/InvitationEventBanner.vue';
import InvitationEventMiddleAlt from '@/views/components/coaching/event/InvitationEventMiddleAlt.vue';
import InvitationEventGuideAlt from '@/views/components/coaching/event/InvitationEventGuideAlt.vue';
import InvitationEventBottomBannerAlt from '@/views/components/coaching/event/InvitationEventBottomBannerAlt.vue';
import InvitationEventBottomDetailAlt from '@/views/components/coaching/event/InvitationEventBottomDetailAlt.vue';

export default {
  name: 'CoachEventInvitationReceive',
  components: { ColorButton, InvitationEventBanner, InvitationEventMiddleAlt, InvitationEventGuideAlt, InvitationEventBottomBannerAlt, InvitationEventBottomDetailAlt },
  data: () => ({
    invitationCode: '',
  }),
  metaInfo() {
    return {
      title: this.$t('meta.event.receive.title'),
      meta: [
        { vmid: 'title', content: this.$t('meta.event.receive.title') },
        { vmid: 'description', content: this.$t('meta.event.receive.description') },
        { vmid: 'url', content: 'https://co.lvup.gg/event/receive' },
        { vmid: 'site_name', content: this.$t('meta.siteName') },
        { vmid: 'type', content: 'website' },
        { vmid: 'image', content: `${process.env.VUE_APP_HOST}/img/meta/LVUP-event.png` },
        { vmid: 'keywords', content: this.$t('meta.event.receive.keywords') },
      ],
    };
  },
  computed: {
    userInfo() {
      return this.$store?.state?.auth?.myInfo;
    },
    isCoach() {
      return this.myInfo?.isCoach || false;
    },
  },
  methods: {
    prevBtnHandler() {
      this.$router.push({ name: 'CoachHome' });
    },
    goSend() {
      this.$router.push({ name: 'CoachEventInvitation' });
    },
    async receive() {
      if (!this.userInfo) return this.$services.auth.oAuthLogin(true);
      if (this.isCoach) return this.$toast('coaching.FRIENDS_INVITATION_VALIDATION_USER_TYPE', { type: 'fail' });
      if (this.invitationCode === '') return;

      try {
        await this.$services.coaching.postInvitationCode({ invitationCode: this.invitationCode });
      } catch (err) {
        const errCode = err?.[1];
        return this.$toast(`coaching.FRIENDS_INVITATION_RECEIVE_${errCode}`, { type: 'fail' });
      }
    },
  },
  mounted() {
    const invitationCode = this.$route?.query?.['invitation-code'];
    if (invitationCode) this.invitationCode = invitationCode;
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-event-invitation-receive] { .rel;
  .btn-prev { .abs; display: unset; .t(20); .l(20); .flex; .w(40); .h(40); transform: rotate(90deg); .bgc(rgba(0, 0, 0, 0.6)); .br(12);
    &:hover {
      > img { .o(0.4);}
    }
    > img { .m(auto); .o(0.8);}
  }
  .controller-wrap { .flex; flex-direction: column; .p(40, 20); .bgc(#fff); .justify-center; .items-center;
    .controller-top {
      > p {.fs(16); .c(#191919); .bold; .mb(10); .pr(260);}
    }
    .controller-bottom {.flex; .items-center; gap: 20px;
      .invitation-code-input { .w(176); .h(54); .fs(20); .c(#8d1be6); .bgc(#fff); border: solid 1px #191919; .br(20); .p(0, 20);}
      .receive-btn {.bgc(#191919); .h(54); .fs(18); .bold; .br(20); .p(0, 20);}
    }
  }

  @media (@tp-up) {
    .btn-prev {.hide;}
    .controller-wrap { flex-direction: row; .p(60, 20); gap: 20px;
      .controller-top {
        > p {.fs(24); .pr(0); }
      }
      .controller-bottom { gap: 20px;
        .invitation-code-input { .w(333); .h(74); .fs(20); }
        .receive-btn { .h(74); .fs(24);}
      }
    }
  }

}
</style>
