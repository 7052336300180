<template>
  <table table-benefits>
    <slot></slot>
  </table>
</template>

<script>
export default {
  name: 'TableBenefits',
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[table-benefits] { .w(inherit); table-layout: initial; }

</style>
