<template>
  <div invitation-event-guide-alt>
    <div class="title-wrap">
      <h2>무료 수강권 <em>이렇게 사용하세요!</em></h2>
    </div>
    <div class="figure-wrap">
      <figure class="coach-caption-figure">
        <figcaption class="caption-wrap">
          <div class="caption">
            <em>1</em>
          </div>
          <p>{{ $t('coaching.MC_DESCRIPTION_HOW_TO_A_SEND_REFERRAL') }}</p>
        </figcaption>
        <div class="figure-img-wrap first" />
      </figure>
      <figure class="coach-caption-figure">
        <figcaption class="caption-wrap">
          <div class="caption">
            <em>2</em>
          </div>
          <p>{{ $t('coaching.MC_DESCRIPTION_HOW_TO_B_SEND_REFERRAL') }}</p>
        </figcaption>
        <div class="figure-img-wrap second" />
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvitationEventGuideAlt',
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[invitation-event-guide-alt] { .p(40, 0); .bgc(#fff);
  .title-wrap { .mb(20); text-align: center;
    > h2 {.fs(24); .bold; .c(#242a32); font-family: KotraBold;
      > em {.c(#8d1be7);}
    }
  }
  .figure-wrap { .flex; flex-wrap: wrap; column-gap: 100px; row-gap: 60px; .justify-center;
    .coach-caption-figure {
      .caption-wrap { .flex; .items-center; .mb(20);
        .caption { .flex; .w(40); .h(40); .mr(10); .bg('@{img}/icon/water-drop-purple-small.svg'); object-fit: fill;
          > em { .m(auto); .fs(24); .bold; .c(#fff);}
        }
        > p {.fs(16); .c(#191919);}
      }
    }
    .figure-img-wrap { .w(335); .h(150); object-fit: fill; .m(0, auto);
      &.first {.bg('@{img}/coaching/coach-event-guide-1-small.svg');}
      &.second {.h(146); .bg('@{img}/coaching/coach-event-guide-2-small.svg');}
    }
  }

  @media (@tp-up) {
    .p(100, 0);
    .title-wrap {
      > h2 {.fs(36);
      }
    }
    .figure-wrap { .mb(40);
      .coach-caption-figure {
        .caption-wrap {
          .caption { .w(56); .h(56); .mr(16); .bg('@{img}/icon/water-drop-purple.svg');
            > em { .m(auto); .fs(40);}
          }
          > p {.fs(24);}
        }
      }
      .figure-img-wrap { .w(590); .h(231); object-fit: fill;
        &.first {.bg('@{img}/coaching/coach-event-guide-1.svg');}
        &.second {.h(231); .bg('@{img}/coaching/coach-event-guide-2.svg');}
      }
    }
  }
}

</style>
