<template>
  <div ref="bannerEl" :class="{receive : bannerType === 'receive'}" invitation-event-banner>
    <div class="title-wrap">
      <div class="balloon">
        <em>{{ $t('coaching.MC_BI_LVUPCC') }}</em>
      </div>
      <h3 v-if="bannerType === 'send'" class="title-text">
        친구에게<br>무료 수강권 보내면<br><em>100만원 드려요!</em>
      </h3>
      <h3 v-else class="title-text">
        친구로부터<br><em>무료 수강권</em><br>도착했어요
      </h3>
      <h4 v-if="bannerType === 'send'" class="sub-title-text">
        내 친구가 첫 수업 들으면 <br><em>1만원 상당</em>의 친구 초대 보상금이!<br><em>최대 100만원까지</em> 받을 수 있어요!
      </h4>
      <h4 v-if="bannerType === 'receive'" class="sub-title-text">
        내 친구에게 받은 초대코드 입력하면<br>첫 수업 <em>무료 수강권</em> 지급!<br>모든 수업 <em>50% 할인</em> 쿠폰까지!
      </h4>
    </div>
    <div class="img-wrap">
      <img :data-animation="'people-animation'" class="woman people people-animation" src="/img/icon/woman-figure.png">
      <img :data-animation="'people-animation'" class="man people people-animation" src="/img/icon/man-figure.png">
      <img
        v-for="i in 7"
        :key="'left-' + i"
        :class="['coupon', `coupon-left-${i}`, 'coupon-left']"
        :data-animation="`coupon-left-${i}`"
        :src="`/img/coaching/coach-event-${bannerType}-coupon-left-${i}.svg`"
      />

      <img
        v-for="i in 6"
        :key="'right-' + i"
        :class="['coupon', `coupon-right-${i}`, 'coupon-right']"
        :data-animation="`coupon-right-${i}`"
        :src="`/img/coaching/coach-event-${bannerType}-coupon-right-${i}.svg`"
      />
    </div>
  </div>
</template>

<script>
import '@/utils/polyfillUtils';

export default {
  name: 'InvitationEventBanner',
  props: {
    bannerType: { type: String, default: 'send' }, /** string: 'send' | 'receive */
  },
  computed: {
    isReceive() {
      return this.bannerType === 'receive';
    },
  },
  methods: {
    handlerScroll() {
      const el = this.$refs.bannerEl;
      const targetEls = el.querySelectorAll('.coupon, .people');
      targetEls.forEach(targetEl => {
        const animationName = targetEl.dataset.animation || '';
        targetEl.classList.add(animationName);
      });

      this.firstScroll = true;
      window.removeEventListener('scroll', this.handlerScroll);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.handlerScroll);
      const el = this.$refs.bannerEl;
      if (!el) return;
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          const targetEls = entry.target.querySelectorAll('.coupon, .people');

          if (entry.intersectionRatio > 0.3 && this.firstScroll) {
            targetEls.forEach(targetEl => {
              const animationName = targetEl.dataset.animation || '';
              targetEl.classList.add(animationName);
            });
          } else {
            targetEls.forEach(targetEl => {
              const animationName = targetEl.dataset.animation || '';
              targetEl.classList.remove(animationName);
            });
          }
        });
      }, { threshold: [0.0, 0.3] });
      observer.observe(el);
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handlerScroll);
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[invitation-event-banner] { .flex; flex-direction: column; .h(522); .bgc(#2f50f8); .pt(68); overflow: hidden;
  .title-wrap { .abs; .l(0); .r(0); text-align: center; .z(1);
    .balloon { .flex; .items-center; .justify-center; .w(197); .h(49); .m(0, auto, 16); .bg('@{img}/icon/speech-balloon-small.svg'); .pb(12);
      > em { .fs(14); .bold; .c(#2f50f8); }
    }

    .title-text {.fs(36); .lh(46); line-break: unset; .nowrap; .c(#fff); font-family: KotraBold;
      > em { .c(#fed1ff); }
    }
    .sub-title-text {.mt(28); .fs(18); .bold; .c(#fff); text-align: center; .lh(26);
      > em { .c(#fed1ff); }
    }
  }
  .img-wrap { .rel; .mt(auto);
    .woman { .abs; .b(0); .r(calc(50%)); .w(102); }
    .man {.abs; .b(0); .l(calc(50%)); .w(120); }

    .people {
      &.people-animation {
        animation-delay: 100ms;
        animation-duration: 600ms;
        animation-name: people-animation;
      }
      &.man-img {
        position: absolute;
        bottom: 0;
        left: calc(50%);
        width: 256px;
      }

      &.woman-img {
        position: absolute;
        bottom: 0;
        right: calc(50%);
        width: 218px;
        margin: 0 6px;
      }
    }

    .coupon { .abs;
      transition-timing-function: linear;
      animation-duration: 1500ms;
      &.coupon-left-1 {
        width: 117px;
        height: 138px;
        bottom: -40px;
        right: calc(50% + 152px);
        animation-name: coupon-animation-left-1;
      }
      &.coupon-left-2 {
        width: 68px;
        height: 93px;
        bottom: 83px;
        right: calc(50% + 186px);
        animation-name: coupon-animation-left-2;
      }
      &.coupon-left-3 {
        width: 45px;
        height: 26px;
        bottom: 114px;
        right: calc(50% + 130px);
        animation-name: coupon-animation-left-3;
      }
      &.coupon-left-4 {
        width: 4px;
        height: 4px;
        bottom: 114px;
        right: calc(50% + 203px);
        animation-name: coupon-animation-left-4;

        @keyframes coupon-animation-left-4 {
          0% {
            opacity: 0;
            right: calc(50% + 153px);
          }

          80% {
            opacity: 1;
          }

          100% {
            transform: translate(0, 0);
          }
        }
      }
      &.coupon-left-5 {
        width: 13px;
        bottom: 84px;
        right: calc(50% + 176px);
        animation-name: coupon-animation-left-5;

        @keyframes coupon-animation-left-5 {
          0% {
            opacity: 0;
            right: calc(50% + 126px);
            bottom: 74px;
          }

          80% {
            opacity: 1;
          }

          100% {
            transform: translate(0, 0);
          }
        }
      }
      &.coupon-left-6 {
        width: 9px;
        bottom: 96px;
        right: calc(50% + 136px);
        animation-name: coupon-animation-left-6;

        @keyframes coupon-animation-left-6 {
          0% {
            opacity: 0;
            right: calc(50% + 86px);
            bottom: 86px;
          }

          80% {
            opacity: 1;
          }

          100% {
            transform: translate(0, 0);
          }
        }
      }
      &.coupon-left-7 {
        width: 4px;
        bottom: 132px;
        right: calc(50% + 111px);
        animation-name: coupon-animation-left-7;

        @keyframes coupon-animation-left-7 {
          0% {
            opacity: 0;
            bottom: 122px;
            right: calc(50% + 61px);
          }

          80% {
            opacity: 1;
          }

          100% {
            transform: translate(0, 0);
          }
        }
      }

      &.coupon-right-1 {
        width: 142px;
        left: calc(50% + 121px);
        bottom: -30px;
        animation-name: coupon-animation-right-1;

        @keyframes coupon-animation-right-1 {
          0% {
            opacity: 0;
            transform: translate(-130%, -30%);
          }

          80% {
            opacity: 1;
          }

          100% {
            transform: translate(0, 0);
          }
        }
      }
      &.coupon-right-2 {
        width: 68px;
        height: 70px;
        left: calc(50% + 132px);
        bottom: 86px;
        animation-name: coupon-animation-right-2;
      }
      &.coupon-right-3 {
        width: 6px;
        height: 6px;
        left: calc(50% + 215px);
        bottom: 78px;
        animation-name: coupon-animation-right-3;

        @keyframes coupon-animation-right-3 {
          0% {
            opacity: 0;
            left: calc(50% + 165px);
            bottom: 68px;
          }

          80% {
            opacity: 1;
          }

          100% {
            transform: translate(0, 0);
          }
        }
      }
      &.coupon-right-4 {
        width: 18px;
        height: 18px;
        left: calc(50% + 236px);
        bottom: 60px;
        animation-name: coupon-animation-right-4;

        @keyframes coupon-animation-right-4 {
          0% {
            opacity: 0;
            left: calc(50% + 189px);
            bottom: 50px;
          }

          80% {
            opacity: 1;
          }

          100% {
            transform: translate(0, 0);
          }
        }
      }
      &.coupon-right-5 {
        width: 11px;
        height: 11.5px;
        left: calc(50% + 243px);
        bottom: 116px;
        animation-name: coupon-animation-right-5;

        @keyframes coupon-animation-right-5 {
          0% {
            opacity: 0;
            left: calc(50% + 193px);
            bottom: 106px;
          }

          80% {
            opacity: 1;
          }

          100% {
            transform: translate(0, 0);
          }
        }
      }
      &.coupon-right-6 {
        width: 7px;
        height: 8px;
        left: calc(50% + 181px);
        bottom: 150px;
        animation-name: coupon-animation-right-6;

        @keyframes coupon-animation-right-6 {
          0% {
            opacity: 0;
            left: calc(50% + 131px);
            bottom: 140px;
          }
          80% {
            opacity: 1;
          }
          100% {
            transform: translate(0, 0);
          }
        }
      }
    }
  }

  @media (@tp-up) {
    .h(834); .pt(100);
    .title-wrap {
      .balloon { .w(247); .h(56); .m(0, auto, 16); .bg('@{img}/icon/speech-balloon.svg');
        > em {.fs(18);}
      }

      .title-text {.fs(68); .lh(88);}
      .sub-title-text { .fs(24); .lh(36); }
    }
    .img-wrap {
      .woman { .w(218); }
      .man { .w(256); }
      .coupon {
        &.coupon-left-1 {
          width: 257px;
          height: 240px;
          bottom: -64px;
          right: calc(50% + 275px);
        }
        &.coupon-left-2 {
          width: 138px;
          height: 160px;
          bottom: 164px;
          right: calc(50% + 388px);
        }
        &.coupon-left-3 {
          width: 84px;
          height: 104px;
          bottom: 244px;
          right: calc(50% + 278px);
        }
        &.coupon-left-4 {
          width: 9px;
          bottom: 124px;
          right: calc(50% + 434px);
          @keyframes coupon-animation-left-4 {
            0% {
              opacity: 0;
              right: calc(50% + 334px);
            }

            80% {
              opacity: 1;
            }

            100% {
              transform: translate(0, 0);
            }
          }
        }
        &.coupon-left-5 {
          width: 29px;
          bottom: 164px;
          right: calc(50% + 332px);

          @keyframes coupon-animation-left-5 {
            0% {
              opacity: 0;
              right: calc(50% + 232px);
              bottom: 154px;
            }

            80% {
              opacity: 1;
            }

            100% {
              transform: translate(0, 0);
            }
          }
        }
        &.coupon-left-6 {
          width: 20px;
          position: absolute;
          bottom: 206px;
          right: calc(50% + 278px);
          @keyframes coupon-animation-left-6 {
            0% {
              opacity: 0;
              right: calc(50% + 178px);
              bottom: 196px;
            }

            80% {
              opacity: 1;
            }

            100% {
              transform: translate(0, 0);
            }
          }
        }
        &.coupon-left-7 {
          width: 9px;
          bottom: 282px;
          right: calc(50% + 238px);
          @keyframes coupon-animation-left-7 {
            0% {
              opacity: 0;
              bottom: 272px;
              right: calc(50% + 138px);
            }

            80% {
              opacity: 1;
            }

            100% {
              transform: translate(0, 0);
            }
          }
        }

        &.coupon-right-1 {
          width: 298px;
          left: calc(50% + 267px);
          bottom: -64px;
          @keyframes coupon-animation-right-1 {
            0% {
              opacity: 0;
              transform: translate(-130%, -30%);
            }

            80% {
              opacity: 1;
            }

            100% {
              transform: translate(0, 0);
            }
          }
        }
        &.coupon-right-2 {
          width: 144px;
          height: 152px;
          left: calc(50% + 263px);
          bottom: 174px;
        }
        &.coupon-right-3 {
          width: 14px;
          height: 14px;
          left: calc(50% + 414px);
          bottom: 167px;
          @keyframes coupon-animation-right-3 {
            0% {
              opacity: 0;
              left: calc(50% + 314px);
              bottom: 157px;
            }

            80% {
              opacity: 1;
            }

            100% {
              transform: translate(0, 0);
            }
          }
        }
        &.coupon-right-4 {
          width: 42px;
          height: 42px;
          left: calc(50% + 459px);
          bottom: 128px;
          @keyframes coupon-animation-right-4 {
            0% {
              opacity: 0;
              left: calc(50% + 359px);
              bottom: 118px;
            }

            80% {
              opacity: 1;
            }

            100% {
              transform: translate(0, 0);
            }
          }
        }
        &.coupon-right-5 {
          width: 24px;
          height: 25px;
          left: calc(50% + 474px);
          bottom: 249px;
          @keyframes coupon-animation-right-5 {
            0% {
              opacity: 0;
              left: calc(50% + 374px);
              bottom: 239px;
            }

            80% {
              opacity: 1;
            }

            100% {
              transform: translate(0, 0);
            }
          }
        }
        &.coupon-right-6 {
          width: 17px;
          height: 18px;
          left: calc(50% + 343px);
          bottom: 301px;

          @keyframes coupon-animation-right-6 {
            0% {
              opacity: 0;
              left: calc(50% + 243px);
              bottom: 291px;
            }

            80% {
              opacity: 1;
            }

            100% {
              transform: translate(0, 0);
            }
          }
        }
      }
    }
  }

  @keyframes people-animation {
    0% {
      transform: translateY(0%);
    }
    50% {
      transform: translateY(-15%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes coupon-animation-left-1 {
    0% {
      opacity: 0;
      transform: translate(130%, -30%);
    }

    80% {
      opacity: 1;
    }

    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes coupon-animation-left-2 {
    0% {
      opacity: 0;
      transform: translate(300%, 55%);
    }

    80% {
      opacity: 1;
    }

    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes coupon-animation-left-3 {
    0% {
      opacity: 0;
      transform: translate(320%, 100%);
    }

    80% {
      opacity: 1;
    }

    100% {
      transform: translate(0, 0);
    }
  }
  @keyframes coupon-animation-right-2 {
    0% {
      opacity: 0;
      transform: translate(-200%, 55%);
    }

    80% {
      opacity: 1;
    }

    100% {
      transform: translate(0, 0);
    }
  }

  &.receive { .bgc(#8d1be7);
    .title-wrap {
      .balloon {
        .bg('@{img}/icon/speech-balloon-alt-small.svg');
        > em {.c(#8d1be7); }
      }
      .title-text > em {.c(#ffe2ab);}
      .sub-title-text > em {.c(#ffe2ab);}
    }
    @media (@tp-up) {
      .title-wrap {
        .balloon {
          .bg('@{img}/icon/speech-balloon-alt.svg');
          > em {.c(#8d1be7); }
        }
      }
    }
  }
}

</style>
